import React, {MouseEventHandler} from 'react';
import './PixelButton.css';
import {Link} from "react-router-dom";

interface PixelButtonProps {
    text: string
    style?: React.CSSProperties
    onclick?: MouseEventHandler
    filename?:string
}

const PixelButton = ({text,style,onclick,filename}: PixelButtonProps) => {
    return (
        <>
            {filename &&
                <Link to={`/resume/${filename}`} className="PixellButton" style={style} onClick={onclick} target={"_blank"} download>{text}</Link>
            }
            {!filename &&
                <button type="button" className="PixellButton" style={style} onClick={onclick}>{text}</button>
            }
        </>    );
};

export default PixelButton;